<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-12">
                                <p>Link: {{ link }}</p>
                                <p>Thời gian bắt đầu: {{ start_time }}</p>
                            </div>

                            <div class="col-md-12">
                                <chat-window
                                    :current-user-id="myUserId"
                                    :rooms="rooms"
                                    :messages="messages"
                                    :room-id="roomId"
                                    :messages-loaded="messagesLoaded"
                                    :show-add-room="false"
                                    :show-audio="false"
                                    :show-reaction-emojis="false"
                                    :rooms-loaded="true"
                                    :message-actions="[{ name: 'replyMessage', title: 'Reply' }]"
                                    :single-room="true"
                                    :text-messages="text_messages"
                                    @fetch-messages="fetchMessages"
                                    @send-message="sendMessage"
                                />
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mqtt from 'mqtt'

export default {
    metaInfo: {
        title: 'Live Room'
    },
    data() {
        return {
            valid: true,
            id: null,
            link: "",
            start_time: new Date(),

            // for chat
            rooms: [],
            messages: [],
            messagesLoaded: false,
            roomId: '',

            text_messages: {
                ROOMS_EMPTY: 'Đang tải cuộc hội thoại...',
                ROOM_EMPTY: 'Đang tải cuộc hội thoại...',
                SEARCH: 'Tìm kiếm',
                CONVERSATION_STARTED: 'Cuộc hội thoại bắt đầu lúc :',
                NEW_MESSAGES: 'Tin nhắn mới',
            },

            // for mqtt
            client: null,
            connection: {
                host: '103.15.50.244',
                port: 8083,
                clean: true, // Reserved session
                connectTimeout: 4000, // Time out
                reconnectPeriod: 4000, // Reconnection interval
                // Certification Information
                clientId: (Math.random() + 1).toString(36).substring(2) + (Math.random() + 1).toString(36).substring(2),
            }
        };
    },
    watch: {
        live(val) {
            this.id = val.Id;
            this.link = val.Fb_link_live;
            this.start_time = new Date(val.Start_time * 1000).toLocaleString();
        }
    },
    computed: {
        live() {
            return this.$store.getters['lives/get_live'];
        },
        myUserId() {
            return 1;
        }
    },
    components: {
        ChatWindow
    },
    methods: {
        createMQTTConnection() {
            var self = this;
            const { host, port, ...options } = this.connection;
            const connectUrl = `ws://${host}:${port}`;
            try {
                this.client = mqtt.connect(connectUrl, options);
                console.log('this..d.sf.sdf', this.client);

                this.client.on('connect', () => {
                    self.fetchRooms();
                    console.log('Connection succeeded!')
                })
                this.client.on('error', error => {
                    console.log('Connection failed', error);
                })
                this.client.on('message', (topic, message) => {
                    // this.receiveNews = this.receiveNews.concat(message)
                    console.log(`Received message ${message} from topic ${topic}`)
                    try {
                        var formattedMessage = self.formatMessage(JSON.parse(message));
                        self.handleNewMessage(topic, formattedMessage);
                    }
                    catch(e) {
                        console.log(e);
                    }
                })
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
        },
        doSubscribe(topic, qos) {
            this.client.subscribe(topic, { qos }, (error, res) => {
                if (error) {
                    console.log('Subscribe to topics error', error)
                    return
                }
                console.log('Subscribe to topics res', res)
            })
        },
        doPublish(topic, message, qos) {
            this.client.publish(topic, message, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        },

        // eslint-disable-next-line no-unused-vars
        async fetchMessages({ room, options = {} }) {
            this.messagesLoaded = false;
            this.roomId = room.roomId;
            var payload = { roomId: room.roomId };
            var messages = await this.$store.dispatch('chat/get_history', payload);
            // console.log("fdsfsdfsdf messages", messages);
            messages = messages.Message;
            
            if(messages) {
                var tmp_messages = [];
                for(var i = 0; i < messages.length; i++) {
                    if(messages[i].Typemess == 1) {
                        var tmp_object = {
                            content: messages[i].Content,
                            date: new Date(messages[i].Create_time * 1000).toLocaleDateString(),
                            seconds: messages[i].Create_time,
                            senderId: messages[i].User_id,
                            sender_id: messages[i].User_id,
                            timestamp: new Date(messages[i].Create_time * 1000).getHours() + ':' + new Date(messages[i].Create_time * 1000).getMinutes(),
                            username: null,
                            _id: messages[i].Id,
                        };
                        tmp_messages.push(tmp_object);
                    }
                }
                this.messages = tmp_messages;
            } else {
                this.messages = [];
            }

            this.doPublish(room.topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
            
            this.messagesLoaded = true;
        },

        async fetchRooms() {
            var self = this;
            var room = await this.$store.dispatch('chat/get_live_room', { liveId: self.$route.params.id });
            if(room) {
                var tmp_room = {
                    roomId: room.Id,
                    avatar: room.Thumbnail || '/media/logos/detective.png',
                    roomName: room.Description,
                    topic: room.Room_name,
                    unreadCount: room.Unread || 0,
                    users: [
                        {
                            _id: 1,
                            username: "Admin"
                        }
                    ]
                };
                if(room.LastMessage) {
                    tmp_room["lastMessage"] = {
                        content: room.LastMessage.Content,
                        distributed: true,
                        new: false,
                        seen: undefined,
                        sender_id: room.LastMessage.User_id,
                        timestamp: new Date(room.LastMessage.Create_time * 1000).toLocaleDateString(),
                    };
                }
                this.doSubscribe(room.Room_name, 0);
                this.rooms = [tmp_room];
            }
            
            // console.log("4234234234234", rooms);
        },

        sendMessage({ content, roomId, replyMessage }) {
            var topic = null;
            var room = this.rooms.find(r => r.roomId == roomId);
            if(room) topic = room.topic;

            if(topic) {
                var mqtt_message = {
                    User_id: 1,
                    Content: content,
                    Typemess: replyMessage ? 2 : 1
                }
                if(replyMessage) {
                    mqtt_message["Parent_message"] = {
                        User_id: replyMessage.senderId
                    }
                }
                this.doPublish(topic, JSON.stringify(mqtt_message), 0);
                this.doPublish(topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
            }
        },

        // eslint-disable-next-line no-unused-vars
        handleNewMessage(topic, message) {
            // find roomId by topic
            var roomIdx = this.rooms.findIndex(r => r.topic == topic);
            if(roomIdx > -1) {
                var roomId = this.rooms[roomIdx].roomId;
                if(!message.Typemess) {
                    if(message.senderId != this.myUserId) {
                        this.rooms[roomIdx].lastMessage = message;
                        console.log("fdsfdsfsdf", roomId, this.roomId)
                        if(this.roomId == roomId) {
                            this.messages = this.messages.concat([message]);
                            this.doPublish(this.rooms[roomIdx].topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
                        } else {
                            this.rooms[roomIdx].unreadCount = this.rooms[roomIdx].unreadCount ? this.rooms[roomIdx].unreadCount + 1 : 1;
                        }
                    } else {
                        this.rooms[roomIdx].lastMessage = message;
                        this.messages = this.messages.concat([message]);
                    }
                }
                if(message.Typemess == 3) {
                    this.rooms[roomIdx].unreadCount = 0;
                }
            }
        },
        formatMessage(message) {
            if(message.Typemess == 1) {
                var tmp_object = {
                    content: message.Content,
                    date: new Date().toLocaleDateString(),
                    seconds: Math.floor(new Date().getTime() / 1000),
                    senderId: message.User_id,
                    sender_id: message.User_id,
                    timestamp: new Date().getHours() + ':' + new Date().getMinutes(),
                    username: null,
                    _id: new Date().getTime(),
                };
                return tmp_object;
            } else if(message.Typemess == 3) {
                return message;
            } else {
                return message;
            }
            
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Livestream", route: "/lives/index" }, { title: "Sửa livestream" } ]);
        // this.$store.dispatch('products/get_all', {pageSize: 1000, pageIndex: 1, categoryId: -1});
        this.$store.dispatch('lives/get_live_by_id', {id: this.$route.params.id});
        this.createMQTTConnection();
    }
};
</script>



